import React, { Component } from 'react';
import './HomeParallax.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Parallax from 'parallax-js'
import line_creatividad from '../../assets/images/parallax/line-creatividad.png';
import line_marketing from '../../assets/images/parallax/line-marketing.png';
import line_media from '../../assets/images/parallax/line-media.png';
import line_estrategia from '../../assets/images/parallax/line-estrategia.png';
import line_innovacion from '../../assets/images/parallax/line-innovacion.png';
import Data_Icon from '../../assets/images/parallax/Data_Icon.png';
import Creatividad_Icon from '../../assets/images/parallax/Creatividad_Icon.png';
import Media_Icon from '../../assets/images/parallax/Media_Icon.png';
import Content_Marketing_Icon from '../../assets/images/parallax/Content_Marketing-_Icon.png';
import Innovacion_Icon from '../../assets/images/parallax/Innovacion_Icon.png';
import Estrategia_Icon from '../../assets/images/parallax/Estrategia_Icon.png';

AOS.init();

class HomeParallax extends Component{
    componentDidMount() {
        this.parallax = new Parallax(this.scene)
    }
    componentWillUnmount() {
        this.parallax.disable()
    }
    render(){
        return (
            <div className="content-parallax">
            <div className="container" data-aos="zoom-out-down" data-aos-duration="1000">
                <div className="div-icon">
                    <ul id="scene" className="scene border1 fill"  ref={el => this.scene = el}>
                        <li className="layer expand-width" data-depth="1.50" style={{height: '1px !important'}}>
                            <img className="icon-move line-creatividad" src={line_creatividad} alt="Imagen" />
                        </li>
                        <li className="layer expand-width" data-depth="1.90" style={{height: '1px !important'}}>
                            <img className="icon-move line-marketing" src={line_marketing} alt="Imagen" />
                        </li>
                        <li className="layer expand-width" data-depth="1.80" style={{height: '1px !important'}}>
                            <img className="icon-move line-media" src={line_media} alt="Imagen" />
                        </li>
                        <li className="layer expand-width" data-depth="1.70" style={{height: '1px !important'}}>
                            <img className="icon-move line-estrategia" src={line_estrategia} alt="Imagen" />
                        </li>
                        <li className="layer expand-width" data-depth="1.60" style={{height: '1px !important'}}>
                            <img className="icon-move line-innovacion" src={line_innovacion} alt="Imagen" />
                        </li>
                        <li className="layer expand-width" data-depth="2.40" style={{height: '1px !important'}}>
                            <img className="icon-move icon-data" src={Data_Icon} alt="Imagen" />
                        </li>
                        <li className="layer expand-width" data-depth="1.00" style={{height: '1px !important'}}>
                            <span className="icon-center icon-creatividad" >Creatividad&nbsp;&nbsp;
                                <img className="icon-move img-creatividad" src={Creatividad_Icon}alt="Imagen" />
                            </span>
                        </li>
                        <li className="layer expand-width" data-depth="1.20" style={{height: '1px !important'}}>
                            <span className="icon-center icon-media">
                                <img className="icon-move img-media" src={Media_Icon} alt="Imagen" />&nbsp;&nbsp;Media
                            </span>
                        </li>
                        <li className="layer expand-width" data-depth="1.40" style={{height: '1px !important'}}>
                            <span className="icon-center icon-marketing">
                                Content Marketing&nbsp;
                                <img className="icon-move img-marketing" src={Content_Marketing_Icon} alt="Imagen"/>
                            </span>
                        </li>
                        <li className="layer expand-width" data-depth="1.30" style={{height: '1px !important'}}>
                            <span className="icon-center icon-innovacion">
                                <img className="icon-move img-innovacion" src={Innovacion_Icon} alt="Imagen"/>
                                &nbsp;&nbsp;Innovación
                            </span>
                        </li>
                        <li className="layer expand-width" data-depth="1.10" style={{height: '1px !important'}}>
                            <span className="icon-center icon-estrategia">
                                <img className="icon-move img-estrategia" src={Estrategia_Icon} alt="Imagen"/>
                                &nbsp;&nbsp;Estrategia
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            </div>
        );
    }
}

export default HomeParallax;