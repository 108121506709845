import React from 'react';
import './Contact.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';

AOS.init();

function Contact(){
    return (
        <div className="content-contact w-100">
            <div className="container" data-aos="fade-right" data-aos-duration="1000">
                <h2 className="text-center">Contáctanos</h2>
                <h3 className="text-center">
                    <big>
                        <FontAwesomeIcon icon={faEnvelopeOpenText}/>
                    </big>
                </h3>
                <div className="col-lg-8 offset-lg-2">
                    <form>
                        <div className="col-md-12 pt-3">
                            <label>Nombre</label>
                            <input type="text" name="" className="form-control input-creando " />
                        </div>
                        <div className="col-md-12 pt-3">
                            <label>Correo electrónico</label>
                            <input type="text" name="" className="form-control input-creando" />
                        </div>
                        <div className="col-md-12 pt-3">
                            <label>Asunto</label>
                            <input type="text" name="" className="form-control input-creando" />
                        </div>
                        <div className="col-md-12 pt-3">
                            <label>Mensaje</label>
                            <textarea rows="5" className="form-control input-creando"></textarea>
                        </div>
                        <div className="col-md-12 pt-3">
                            <button type="button" name="" className="btn button-creando">&nbsp;&nbsp;ENVIAR&nbsp;&nbsp;</button>
                        </div>
                    </form>
                </div>
                <p>&nbsp;</p>
            </div>
        </div>
    );
}

export default Contact;
