import React from 'react';
import './App.css';

import { Switch, Route } from "react-router-dom";

import MarketingEstrategia from './components/Main/MarketingEstrategia';
import MediaTecnologia from './components/Main/MediaTecnologia';
import Innovacion from './components/Main/Innovacion';
import Proyectos from './components/Main/Proyectos';
import Contacto from './components/Main/Contacto';
import Home from './components/Main/Home';

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Home}>
        </Route>
        <Route path="/marketing-estrategia" component={MarketingEstrategia}>
        </Route>
        <Route path="/media-tecnologia" component={MediaTecnologia}>
        </Route>
        <Route path="/innovacion" component={Innovacion}>
        </Route>
        <Route path="/proyectos" component={Proyectos}>
        </Route>
        <Route path="/contacto" component={Contacto}>
        </Route>
      </Switch>
    </div>
  );
}

export default App;
