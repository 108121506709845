import React, { Component } from 'react';

import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './CustomersCarousel.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

import Biottek from '../../assets/images/customers/Biottek.png';
import Pelver from '../../assets/images/customers/Pelver.png';
import Provincia from '../../assets/images/customers/Provincia.png';
import RaulPereda from '../../assets/images/customers/RaulPereda.png';
import SpaPerros from '../../assets/images/customers/SpaPerros.png';

AOS.init();

class CustomersCarousel extends Component {
    responsive = {
        768: {
            items: 3,
        },
        1000: {
            items: 5,
        },
    };
    render(){
        return (
            <div className="content-owl w-100">
                <div class="container" data-aos="zoom-in-down">
                    <OwlCarousel items={2}  
                        className="owl-theme"  
                        loop  
                        autoplay={true}
                        nav={false}
                        dost={false}
                        margin={10} 
                        responsive={this.responsive}
                        >  
                        <div className="item"> 
                            <center>
                                <img src={Biottek} alt="Eukanuba" className="img-prod text-center" height="270px" />
                            </center> 
                        </div>
                        <div className="item"> 
                            <center>
                                <img src={SpaPerros} alt="Eukanuba" className="img-prod text-center" height="270px" />
                            </center> 
                        </div>
                        <div className="item"> 
                            <center>
                                <img src={Provincia} alt="Eukanuba" className="img-prod text-center" height="270px" />
                            </center> 
                        </div>
                        <div className="item"> 
                            <center>
                                <img src={Pelver} alt="Eukanuba" className="img-prod text-center" height="270px" />
                            </center>
                        </div>
                        <div className="item"> 
                            <center>
                                <img src={RaulPereda} alt="Eukanuba" className="img-prod text-center" height="270px" />
                            </center>
                        </div>
                    </OwlCarousel>
                </div> 
            </div>
        );
    }
}

export default CustomersCarousel;