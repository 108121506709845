import React from 'react';

import Footer from '../Footer/Footer';
import CustomersCarousel from '../CustomersCarousel/CustomersCarousel';
import Boletin from '../Boletin/Boletin';
import Contact from '../Contact/Contact';
import Header from '../Header/Header';

function Contacto(){
    return(
        <React.Fragment>
            <Header/>
            <Boletin />
            <Contact />
            <CustomersCarousel />
            <Footer />
        </React.Fragment>
    );
}

export default Contacto;