import React from 'react';
import './Footer.css';

import { Link } from "react-router-dom";
import LogoCreando from '../../assets/images/logo-creandodm.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook,faTwitter,faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

function Footer(){
    return (
        <footer>
            <div className="container">
                <div className="footer-logo">
                    <Link to="/">
                        <img src={LogoCreando} className="fa-animate" alt="Logo CreandoDM" />
                    </Link>
                </div>
                <div class="social-links text-center">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/">
                        <big><FontAwesomeIcon icon={faFacebook} /></big>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/">
                        <big><FontAwesomeIcon icon={faTwitter} /></big>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/">
                        <big><FontAwesomeIcon icon={faLinkedinIn} /></big>
                    </a>
                </div>
                <div class="separator">&nbsp;</div>
                <div className="text-center">
                    &copy; 2020 Creando Inc Digital Media • All Rights Reserved  
                </div>     
            </div>
        </footer>
    );
}

export default Footer;