import React from 'react';
import './Boletin.css';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Boletin(){
    return (
        <div className="content-boletin w-100">
            <div className="container" data-aos="fade-up">
                <h2 className="text-center">¡Suscríbete al boletin de noticias de Creando ahora!</h2>
                <p>&nbsp;</p>
                <div className="col-lg-8 offset-lg-2">
                    <form className="row">
                        <div className="col-md-8 col-sm-8 col-xs-12" style={{paddingBottom: '20px'}}>
                            <input type="text" className="form-control input-creando"  placeholder="Ingrese su email" />
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12 text-center">
                            <button type="button" className="btn form-control button-creando" >&nbsp;&nbsp;SUBCRIBIRSE&nbsp;</button>
                        </div>
                    </form>
                </div>
                <p>&nbsp;</p>
            </div>
        </div>
    );
}

export default Boletin;