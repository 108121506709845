import React from 'react';

import HomeSlider from '../HomeSlider/HomeSlider';
import HomeParallax from '../HomeParallax/HomeParallax';
import Footer from '../Footer/Footer';
import CustomersCarousel from '../CustomersCarousel/CustomersCarousel';
import Boletin from '../Boletin/Boletin';
import Contact from '../Contact/Contact';
import Header from '../Header/Header';

function Proyectos(){
    return(
        <React.Fragment>
            <Header />
            <HomeSlider />
            <HomeParallax />
            <CustomersCarousel />
            <Boletin />
            <Contact />
            <Footer />
        </React.Fragment>
    );
}

export default Proyectos;