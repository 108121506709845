import React from 'react';
import './Header.css';

import { Link } from "react-router-dom";
import Logo from '../../assets/images/logo-creandodm.png';

function Header(){
    return (
        
            <nav className="navbar navbar-expand-lg sticky-top navbar-dark justify-content-between">
                <div className="container pt-2">
                    <Link className="navbar-brand pl-3" to="/">
                        <img src={Logo} className="img-responsive" width="150px" alt="Logo" />
                    </Link>
                    <button className="navbar-toggler collapsed mr-3" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-collapse collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active">
                                <Link className="nav-link" to="/marketing-estrategia">Marketing & Estrategia</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/media-tecnologia">Media & Tecnología</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/innovacion">Innovación</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/proyectos">Proyectos</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contacto">Contacto</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        
    );
}

export default Header;